import React from "react"
import Site from "../components/Layout/Site"
import Element from "../components/UI/Element"
import { Container } from "../components/UI/Grid"
import { Section } from "../components/UI/Section"
import Hr from "../components/UI/Hr"
import Seo from "../components/Layout/Seo"
import { Anchor, Paragraph } from "../components/UI/Typography"

const PrivacyPolicy = () => {
  return (
    <Site>
      <Seo title="Privacy Policy" />
      <Section>
        <Container>
          <Element as="h1" fs="h1">
            Privacy Policy
          </Element>
          <Hr bg="#BDBDBD" mb={6} />
          <Paragraph mb={4}>
            This privacy notice for Boeing Material Handling Corp. (“Company,
            “we,” “us,” or “our”), describes how and why we might collect,
            store, use, and/or share (“process”) your information when you use
            our services (“Services”), such as when you:
          </Paragraph>

          <Element ul="ol" pl="4" mb={4}>
            <Element as="li" mb={2}>
              Visit our website at{" "}
              <Anchor
                as="a"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.boeingmhc.com.ph"
              >
                https://www.boeingmhc.com.ph
              </Anchor>
              , or any website of ours that links to this privacy notice
            </Element>
            <Element as="li">
              Engage with us in other related ways, including any sales,
              marketing, or events
            </Element>
          </Element>

          <Paragraph mb={10}>
            Questions or concerns? Reading this privacy notice will help you
            understand your privacy rights and choices. If you do not agree with
            our policies and practices, please do not use our Services. If you
            still have any questions or concerns, please contact us at{" "}
            <Anchor
              as="a"
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:contact@boeingmhc.com.ph"
            >
              contact@boeingmhc.com.ph
            </Anchor>
            .
          </Paragraph>
          <Element as="ol" pl="4">
            <Element as="li" fontWeight="bold">
              WHAT INFORMATION DO WE COLLECT?
              <Element fontWeight="normal" mt={4}>
                Personal information you disclose to us
              </Element>
              <Element fontWeight="normal" mt={4} fontStyle="italic">
                <strong>In Short:</strong> we collect personal information that
                you provide us
              </Element>
              <Element fontWeight="normal" mt={4} mb={10}>
                <Paragraph mb={4}>
                  We collect personal information that you voluntarily provide
                  to us when you express an interest in obtaining information
                  about us or our products and Services, when you participate in
                  activities on the Services, or otherwise when you contact us.
                </Paragraph>
                <Paragraph mb={4}>
                  <strong>Personal Information Provided by You</strong>. The
                  personal information that we collect depends on the context of
                  your interactions with us and the Services, the choices you
                  make, and the products and features you use. The personal
                  information we collect may include the following:
                </Paragraph>
                <Element as="ul" pl="8" type="disc">
                  <Element as="li" mb={2}>
                    Names
                  </Element>
                  <Element as="li" mb={2}>
                    Phone Numbers
                  </Element>
                  <Element as="li" mb={4}>
                    Email Addresses
                  </Element>
                </Element>
                <Paragraph mb={4}>
                  <strong>Sensitive Information</strong>. We do not process
                  sensitive information.
                </Paragraph>
                <Paragraph mb={4}>
                  All personal information that you provide to us must be true,
                  complete, and accurate, and you must notify us of any changes
                  to such personal information.
                </Paragraph>
                <Paragraph mb={4} fontWeight="bold">
                  Information automatically collected
                </Paragraph>
                <Element fontWeight="normal" mb={4} fontStyle="italic">
                  <strong>In Short:</strong> we collect personal information
                  that you provide us
                </Element>
                <Paragraph mb={4}>
                  We automatically collect certain information when you visit,
                  use, or navigate the Services. The information does not reveal
                  your specific identity (like your name or contact information)
                  but may include device and usage information, such as your IP
                  address, browser and device characteristics, operating system,
                  language preferences, referring URLs, device name, country
                  location, information about how and when you use our Services,
                  and other technical information. This information is primarily
                  needed to maintain the security and operation of our Services,
                  and for our internal analytics and reporting purposes.
                </Paragraph>
                <Paragraph>
                  Like many businesses, we also collect information through
                  cookies and similar technologies.
                </Paragraph>
              </Element>
            </Element>
            <Element as="li" fontWeight="bold">
              HOW DO WE PROCESS YOUR INFORMATION?
              <Element fontWeight="normal" mt={4} fontStyle="italic">
                <strong>In Short:</strong> We process your information to
                provide, improve, and administer our Services, communicate with
                you, for security and fraud prevention, and to comply with law.
                We may also process your information for other purposes with
                your consent.
              </Element>
              <Element fontWeight="normal" mt={4} mb={10}>
                <Paragraph mb={2} fontWeight="bold">
                  We process your personal information for a variety of reasons,
                  depending on how you interact with our Services, including:
                </Paragraph>
                <Element as="ul" pl="8" type="disc">
                  <Element as="li" mb={2}>
                    <strong>
                      To respond to user inquiries/offer support to users.
                    </strong>{" "}
                    We may process your information to respond to your inquiries
                    and solve any potential issues you might have with the
                    requested service.
                  </Element>
                </Element>
              </Element>
            </Element>
            <Element as="li" fontWeight="bold">
              WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              <Element fontWeight="normal" mt={4} fontStyle="italic">
                <strong>In Short:</strong> We may share information in specific
                situations described in this section and/or with the following
                third parties.
              </Element>
              <Element fontWeight="normal" mt={4} mb={10}>
                <Paragraph mb={2}>
                  We may need to share your personal information in the
                  following situations:
                </Paragraph>
                <Element as="ul" pl="8" type="disc">
                  <Element as="li" mb={2}>
                    <strong>Business Transfers.</strong> We may share or
                    transfer your information in connection with, or during
                    negotiations of, any merger, sale of company assets,
                    financing, or acquisition of all or a portion of our
                    business to another company.
                  </Element>
                  <Element as="li">
                    <strong>When we use Google Maps Platform APIs.</strong> We
                    may share your information with certain Google Maps Platform
                    APIs (e.g., Google Maps API, Places API). To find out more
                    about Google’s Privacy Policy, please refer to this{" "}
                    <Anchor
                      as="a"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://policies.google.com/privacy"
                    >
                      <u>link</u>
                    </Anchor>
                    .
                  </Element>
                </Element>
              </Element>
            </Element>
            <Element as="li" fontWeight="bold">
              HOW LONG DO WE KEEP YOUR INFORMATION?
              <Element fontWeight="normal" mt={4} fontStyle="italic">
                <strong>In Short:</strong> We keep your information for as long
                as necessary to fulfill the purposes outlined in this privacy
                notice unless otherwise required by law.
              </Element>
              <Element fontWeight="normal" mt={4} mb={10}>
                <Paragraph mb={4}>
                  We will only keep your personal information for as long as
                  necessary for the purposes set out in this privacy notice,
                  unless a longer retention period is required or permitted by
                  law (such as tax, accounting, or other legal requirements). No
                  purpose in this notice will require us keeping your personal
                  information for longer than 1 year.
                </Paragraph>
                <Paragraph>
                  When we have no ongoing legitimate business need to process
                  your personal information we will either delete or anonymize
                  such information, or, if this is not possible (for example,
                  because your personal information, or, if this is not possible
                  (for example, because your personal information has been
                  stored in backup archives), then we will securely store your
                  personal information and isolate it from any further
                  processing until deletion is possible.
                </Paragraph>
              </Element>
            </Element>
            <Element as="li" fontWeight="bold">
              HOW DO WE KEEP YOUR INFORMATION SAFE?
              <Element fontWeight="normal" mt={4} fontStyle="italic">
                <strong>In Short:</strong> We aim to protect your personal
                information through a system of organizational and technical
                security measures.
              </Element>
              <Element fontWeight="normal" mt={4} mb={10}>
                <Paragraph>
                  We have implemented appropriate and reasonable technical and
                  organizational security measures designed to protect the
                  security of any personal information we process. However,
                  despite our safeguards and efforts to secure your information,
                  no electronic transmission over the Internet or information
                  storage technology can be guaranteed to be 100% secure, so we
                  cannot promise or guarantee that hackers, cybercriminals, or
                  other unauthorized third parties will not be able to defeat
                  our security and improperly collect, access, steal, or modify
                  your information. Although we will do our best to protect your
                  personal information, transmission of personal information to
                  and from our Services is at your own risk. You should only
                  access the Services within a secure environment.
                </Paragraph>
              </Element>
            </Element>
            <Element as="li" fontWeight="bold">
              WHAT ARE YOUR PRIVACY RIGHTS?
              <Element fontWeight="normal" mt={4} fontStyle="italic">
                <strong>In Short:</strong> You may review, change, or terminate
                your account at any time.
              </Element>
              <Element fontWeight="normal" mt={4} mb={10}>
                <Paragraph mb={4}>
                  If you are located in the EEA or UK and you believe we are
                  unlawfully processing your personal information, you also have
                  the right to complain to your local data protection
                  supervisory authority. You can find their contact details
                  here:{" "}
                  <Anchor
                    as="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://ec.europa.eu/newsroom/article29/items/612080/en"
                  >
                    <u>
                      https://ec.europa.eu/newsroom/article29/items/612080/en
                    </u>
                  </Anchor>
                  .
                </Paragraph>
                <Paragraph mb={4}>
                  If you are located in Switzerland, the contact details for the
                  data protection authorities are available here:{" "}
                  <Anchor
                    as="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                  >
                    <u>https://www.edoeb.admin.ch/edoeb/en/home.html</u>
                  </Anchor>
                  .
                </Paragraph>
                <Paragraph mb={4}>
                  <b>
                    <u>Withdrawing your consent:</u>
                  </b>{" "}
                  If we are relying on your consent to process your personal
                  information, which may be express and/or implied consent
                  depending on the applicable law, you have the right to
                  withdraw your consent at any time. You can withdraw your
                  consent at any time by contacting us by using the contact
                  details provided in the section “HOW CAN YOU CONTACT US ABOUT
                  THIS NOTICE?” below.
                </Paragraph>
                <Paragraph>
                  However, please note that this will not affect the lawfulness
                  of the processing before its withdrawal, nor when applicable
                  law allows, will it affect the processing of your personal
                  information conducted in reliance on lawful processing grounds
                  other than consent.
                </Paragraph>
              </Element>
            </Element>
            <Element as="li" fontWeight="bold">
              DO WE MAKE UPDATES TO THIS NOTICE?
              <Element fontWeight="normal" mt={4} fontStyle="italic">
                <strong>In Short:</strong> Yes, we will update this notice as
                necessary to stay compliant with relevant laws.
              </Element>
              <Element fontWeight="normal" mt={4} mb={10}>
                <Paragraph>
                  We may update this privacy notice from time to time. The
                  updated version will be indicated by an updated “Revised” date
                  and the updated version will be effective as soon as it is
                  accessible. If we make material changes to this privacy
                  notice, we may notify you either by prominently posting a
                  notice of such changes or by directly sending you a
                  notification. We encourage you to review this privacy notice
                  frequently to be informed of how we are protecting your
                  information.
                </Paragraph>
              </Element>
            </Element>
            <Element as="li" fontWeight="bold">
              HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              <Element fontWeight="normal" mt={4}>
                <Paragraph mb={4}>
                  If you have questions or comments about this notice, you may
                  email us at{" "}
                  <Anchor
                    as="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="mailto:jungheinrich-sales@boeingmhc.com.ph"
                  >
                    <u>jungheinrich-sales@boeingmhc.com.ph</u>
                  </Anchor>{" "}
                  or by post to:
                </Paragraph>
                <Paragraph mb={10}>
                  Boeing Material Handling Corp.
                  <br />
                  5th Floor, AIC Center, 204 Escolta, Binondo, Manila,
                  Philippines
                  <br />
                  Metro Manila 1006
                </Paragraph>
              </Element>
            </Element>
            <Element as="li" fontWeight="bold">
              HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
              <Element fontWeight="normal" mt={4}>
                <Paragraph>
                  Based on the applicable laws of your country, you may have the
                  right to request access to the personal information we collect
                  from you, change that information, or delete it in some
                  circumstances. To request to review, update, or delete your
                  personal information, please contact us at the “HOW CAN YOU
                  CONTACT US ABOUT THIS NOTICE?” above.
                </Paragraph>
              </Element>
            </Element>
          </Element>
        </Container>
      </Section>
    </Site>
  )
}

export default PrivacyPolicy
